#root {
  max-width: 100%;
  margin: 0 ;
  padding: 0;
  text-align: center;
  font-family: 'Roboto', sans-serif;
}

h1 {
  margin: 1rem auto 3rem;
  color: #2A558F;
}



@media(max-width : 800px){
  h1 {
    margin: 1rem auto 1rem;
    color: #2A558F;
  }
}

@media(max-width : 575px){
  #root {
    max-width: 100%;
    padding: 0;
    margin: 0;

    text-align: center;
  }

  h1{
    font-size: 1.3rem;
  }
}




