.title-image{
    padding: 10%;
    width: 100%;
}

.vision-section{
    margin-top: 100px;
}

.outer-div-vision{
    margin-top: 10rem;
}

.blank-img{
    position: absolute;
    z-index: -1;
    width: 80%;
    left: 16%;
    top: 18%;
    opacity: 0.8;
}

.description{
    text-align: justify;
    padding: 10%;
}


.mission_head{
    padding-top: 2rem;
}
.mission{
    color: black;
    font-size: 1.4rem;
}
.feature-head{
    text-align: center;
    padding-top: 5%;
}

.icon_div{
    margin: 0;
    padding: 0;
    justify-content: center;
}

.icon_div img{
    justify-content: center;
    width: 80%;
}

.icon_div img:hover{
    opacity: 0.8;
}



h3{
    padding-left: 2%;
    padding-right: 2%;
    width: 100%;
    text-align: center;
}


.team_Container{
    position: absolute;
    z-index: 1;
    align-items: center;
    width: 60%;
    background-color: #436a9c;
    margin-left: 20%;
    margin-top: 10%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border: 1px solid #2A558F;
    border-radius: 5px;
  }

  .team_Container .team_Content{
    max-width: 100%;
  }

  .team_Container .team_Content .close{
    border: none;
    outline: none;
    background: none;
    cursor: pointer;
    position: absolute;
    top: 7px;
    right: 7px;
  }
  .svg-close{
    width: 27px;
    height: 27px;
  }

  .team_Container .team_Content .team_info{
    /* display: flex;
    justify-content: space-between; */
    margin: 5%;
  }

  .team_Container .team_Content .img_box img{
      width: 50%;
  }

  .team_Container .team_Content .team_info h2{
    padding: 2%;
    color: white;
  }

  .background.blur {
    filter: blur(8px); 
  }



@media(max-width : 800px){
    h3 {
        font-size: 1.2rem;
    }
}

@media(max-width : 640px){
    .description p{
        color: black;
        font-size: 1.1rem;
        padding-top: 1%;
    }
    .feature-head{
        text-align: center;
        padding-top: 1%;
    }

    .description{
        padding: 6%;
    }
}


@media(max-width : 640px){
    .feature .icon_div{
        width: 50%;
    }
    .title-image{
        width: 80%;
    }
    .blank-img{
        position: absolute;
        z-index: -1;
        width: 58%;
        left: 23%;
        top: 19%;
        opacity: 0.8;
    }
}


@media(max-width : 575px){
    .description p{
        color: black;
        font-size: 0.9rem;
        padding-top: 1%;
        width: 100%;
    }
    .mission{
        font-size: 0.9rem;
    }
    .feature-head{
        text-align: center;
        padding-top: 0;
    }

    .description{
        padding: 4%;
    }

    .feature-head{
        width: 80%;
        font-size: 1.2rem;
        text-align: center;
        padding-top: 5%;
    }
    h3{
        font-size: 0.8rem;
    }
    .features{
        width: 50%;
    }
    
}



@media(max-width : 575px){
    .team_Container{
      position: absolute;
      z-index: 1;
      align-items: center;
      width: 80%;
      background-color: #436a9c;
      margin-left: 10%;
      margin-top: 22%;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      border: 1px solid #2A558F;
      border-radius: 5px;
    }
    .team_Container .team_Content .img_box img{
      width: 70%;
  }

  .team_Container .team_Content .team_info h2{
    font-size: 1rem;
    color: white;
  }
  .team_Container .team_Content .team_info p{
    font-size: 0.7rem;
    color: white;
  }
}





