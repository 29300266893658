.home{
    width: 100%;
    margin: 0;
    padding-top: 50px;

}

.home img{
    margin: 0;
    padding: 0;
    width: 100%;
}
