
.Product-items{
    margin-top: 7%;
    /* padding-left: 9%; */
}

.product--image {
    width: 100%;
    height: 180px;
    object-fit: scale-down;
    margin-bottom: 20px;
}

.product--image-desc {
    width: 100%;
    height: 300px;
    object-fit: scale-down;
}

  
  .close{
    color: white;
  }

  .svg-close{
    width: 27px;
    height: 27px;
  }

  .card p{
    color: #000;
  }
  
  .card {
    
    max-width: 70%;
    margin: auto ;
    text-align: justify;
    /* font-family: arial; */
    transition: all 0.3s ease-in;
    padding: 18px;
  }

  .card button {
    border: none;
    outline: 0;
    padding: 12px;
    color: white;
    background-color: #27A1CE;
    text-align: center;
    cursor: pointer;
    width: 100%;
    font-size: 18px;
  }

  .card:hover{
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */
    cursor: context-menu;
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px; */
    transform: scale(1.02);
    z-index: 1;
  }
  
  
  .card button:hover {
    opacity: 0.7;
  }


  .detail_Container{
    position: absolute;
    z-index: 1;
    align-items: center;
    width: 60%;
    background-color: #436a9c;
    margin-left: 20%;
    margin-top: 10%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border: 1px solid #2A558F;
    border-radius: 5px;
  }

  .detail_Container .detail_Content{
    max-width: 100%;
  }

  .detail_Container .detail_Content .close{
    border: none;
    outline: none;
    background: none;
    cursor: pointer;
    position: absolute;
    top: 7px;
    right: 7px;
  }

  .detail_Container .detail_Content .detail_info{
    /* display: flex;
    justify-content: space-between; */
    margin: 5%;
  }

  .detail_Container .detail_Content .img_box img{
      width: 50%;
  }

  .detail_Container .detail_Content .detail_info h2{
    padding: 2%;
    color: white;
  }

  .des-box p{
    text-align: justify;
  }

  .background.blur {
    filter: blur(8px); 
  }

  @media(max-width : 575px){
    .detail_Container{
      position: absolute;
      z-index: 1;
      align-items: center;
      width: 80%;
      background-color: #436a9c;
      margin-left: 10%;
      margin-top: 18%;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      border: 1px solid #2A558F;
      border-radius: 5px;
    }
    .detail_Container .detail_Content .img_box img{
      width: 70%;
  }

  .detail_Container .detail_Content .detail_info h2{
    font-size: 1rem;
    color: white;
  }
  .detail_Container .detail_Content .detail_info p{
    font-size: 0.7rem;
    color: white;
  }
}


@media(max-width : 464px){
  .card p{
    font-size: 12px;
    color: #000;
  }
}
