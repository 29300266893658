.footer{
    background: #2A558F;
    text-align: center;
    margin-top: 100px;
    padding-top: 1%;
    padding-bottom: 1%;
}
.footer h1,p{
    color: white;
}

.footer p{
    font-size: 1.2rem;
}
.social-icon{
    padding-top: 1%;
    color: white;
    margin: 20px 20px;
}

.social-icon:hover{
    transform: scale(1.3);
}


@media(max-width : 575px){

    .footer{
        margin-top: 30px;
    }
    
    .footer h1{
        font-size: 1.2rem;
    }
    .footer p{
        font-size: 1rem;
    }
}
