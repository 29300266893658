.navbar{
    width: 100%;
    /* height: 4rem; */
    padding-top: 1rem;
    padding-bottom: 1rem;
    background: #2f3233;
    position: fixed;
    margin: 0;
    z-index: 1;
    /* margin-bottom: 1rem; */
    /* box-shadow: 0 0 7px 1px rgb(180, 219, 220,1); */
}



.navbar .navbar-brand{
    font-size: 1.8rem;
    color: #2A558F;
    /* text-shadow: -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white, 1px 1px 0 white; */
    margin: 0;
    padding: 0;
    padding-left: 80px;
    letter-spacing: 2px;
    
}

.navbar .navbar-brand:hover{
    transform: scale(1.1);
}

.navbar .navbar-brand span{
    color: #27A1CE;
  /* background-color: white; */
  /* text-shadow: -1px -1px 0 #2A558F, 1px -1px 0 #2A558F, -1px 1px 0 #2A558F, 1px 1px 0 #2A558F; */
}
/* .navbar .navbar-brand .logo{
    width: 40%;
} */

.collapse{
    /* text-align: center; */
    justify-content: right;
    padding-right: 8%;
}

.navbar .navbar-nav .nav-link{
    cursor: pointer;
    color: white;
    /* color: black; */
    font-size: 1.2em;
    font:bold;
    padding: 0px 20px;
}

.navbar .navbar-nav .nav-link:hover{
    text-decoration: underline;
    text-underline-offset: 0.5rem;
    color: white;
    font-size: 1.3em;
}


.nav-link .active {
    text-decoration: underline;
    color: white; 
  }
  


@media(max-width : 990px){
    .navbar{
        margin: 0;
        padding-top: 5px;
        padding-bottom: 5px;
        
    }
    .navbar .navbar-brand{
        font-size: 1.1rem;
        padding-left: 20px;
    }

    .navbar .navbar-nav .nav-link{
        font-size: 10px;
        
    }

    .navbar .navbar-nav .nav-link:hover{
    
        font-size: 12px;
        text-underline-offset: 0rem;
    }
}
