section{
    margin: 0;
    padding: 0;
}
.about-sec{
    margin-top:7%;
    padding-top: 3%;
    padding-bottom: 3%;
    background: #27A1CE;
    margin-left: 0;
    margin-right: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.about-details{
    padding: 10%;
    text-align: justify;
}

.about-details h1{
    color: white;
}

.about-details p{
    color: white;
    font-size: 1.4rem;
}
.aboutImg img{
    width: 80%;
    height: 400px;
}


@media(max-width : 640px){
    .about-details p{
        font-size: 1.1rem;
    }
  }


  @media(max-width : 540px){
    .about-details p{
        font-size: 0.8rem;
        width: 100%;
    }
}
